export default {
  Roles: [
    {
      id: 1,
      rol: 'Manager',
      label: 'Manager',
      modulos: [
        {
          id: 1,
          name: 'Contabilidad',
          read: false,
          write: true,
          delete: false,
        },
        {
          id: 2,
          name: 'Categorias',
          read: false,
          write: false,
          delete: false,
        },
        {
          id: 3,
          name: 'usuarios',
          read: false,
          write: false,
          delete: false,
        },
        {
          id: 4,
          name: 'Accesos',
          read: false,
          write: false,
          delete: false,
        },
        {
          id: 5,
          name: 'roles',
          read: false,
          write: false,
          delete: false,
        },
      ],
    },
    {
      id: 2,
      rol: 'Admin',
      label: 'Admin',
      modulos: [
        {
          name: 'Contabilidad',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'Categorias',
          read: false,
          write: true,
          delete: false,
        },
        {
          name: 'usuarios',
          read: false,
          write: false,
          delete: true,
        },
        {
          name: 'Accesos',
          read: true,
          write: false,
          delete: false,
        },
        {
          name: 'roles',
          read: false,
          write: true,
          delete: false,
        },
      ],
    },
    {
      id: 3,
      rol: 'Editor',
      label: 'Editor',
      modulos: [
        {
          name: 'Contabilidad',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'Categorias',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'usuarios',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'Accesos',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'roles',
          read: false,
          write: true,
          delete: false,
        },
      ],
    },
    {
      id: 4,
      rol: 'Contador',
      label: 'Contador',
      modulos: [
        {
          name: 'Contabilidad',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'Categorias',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'usuarios',
          read: false,
          write: false,
          delete: true,
        },
        {
          name: 'Accesos',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'roles',
          read: false,
          write: false,
          delete: false,
        },
      ],
    },
    {
      id: 5,
      rol: 'Ventas',
      label: 'Ventas',
      modulos: [
        {
          name: 'Contabilidad',
          read: false,
          write: true,
          delete: false,
        },
        {
          name: 'Categorias',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'usuarios',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'Accesos',
          read: false,
          write: false,
          delete: false,
        },
        {
          name: 'roles',
          read: false,
          write: false,
          delete: false,
        },
      ],
    },
  ],
  Regiones: [
    {
      id: 1,
      name: 'Villa Nueva',
      label: 'Villa Nueva',
    },
    {
      id: 2,
      name: 'Zona 11',
      label: 'Zona 11',
    },
    {
      id: 3,
      name: 'Quiche',
      label: 'Quiche',
    },
    {
      id: 4,
      name: 'Zona 21',
      label: 'Zona 21',
    },
  ],
  Modules: [
    {
      id: 1,
      name: 'Contabilidad',
      read: false,
      write: false,
      delete: false,
    },
    {
      id: 2,
      name: 'Categorias',
      read: false,
      write: false,
      delete: false,
    },
    {
      id: 3,
      name: 'usuarios',
      read: false,
      write: false,
      delete: false,
    },
    {
      id: 4,
      name: 'Accesos',
      read: false,
      write: false,
      delete: false,
    },
    {
      id: 5,
      name: 'roles',
      read: false,
      write: false,
      delete: false,
    },
  ],
}
